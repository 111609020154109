@import '_variables/base';

body { margin-top: 2rem; }

.use-motion .menu-item,
.use-motion .sidebar,
.use-motion .sidebar-inner,
.use-motion .post-block,
.use-motion .pagination,
.use-motion .comments,
.use-motion .post-header,
.use-motion .post-body,
.use-motion .collection-header {
  visibility: visible;
}

.use-motion .column,
.use-motion .site-brand-container .toggle,
.use-motion .footer { opacity: initial; }

.use-motion .site-title,
.use-motion .site-subtitle,
.use-motion .custom-logo-image {
  opacity: initial;
  top: initial;
}

.use-motion .logo-line {
  transform: scaleX(1);
}

.search-pop-overlay, .sidebar-nav { display: none; }
.sidebar-panel { display: block; }

.noscript-warning {
  background-color: lighten($red, 20%);
  color: white;
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: bold;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: $zindex-5;
}
